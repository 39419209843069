<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div v-if="!loading" class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          แก้ไขรายการขายส่งสินค้า
        </h3>
        <div class="card-toolbar">
          <div
            v-if="form.is_cancel === '1'"
            style="font-size: 20px; font-weight: bold; color: #b22222"
          >
            - ยกเลิกแล้ว -
          </div>
        </div>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row text-sm-start d-flex justify-content-center mb-4">
          <div class="col-sm-6 mb-4">
            <h4 class="mb-6">
              <i class="bi bi-file-earmark-text" style="font-size: 20px"></i>
              <b>ข้อมูลขายส่งสินค้า</b>
            </h4>
            <label for="selectMainProduct" class="form-label"
              >เลขที่เอกสาร</label
            >
            <input v-model="form.sales_no" disabled class="form-control mb-4" />
            <label for="selectMainProduct" class="form-label"
              >วันที่ออกเอกสาร</label
            >
            <!-- <input disabled class="form-control mb-4" /> -->
            <a-date-picker
              class="form-control ant-date mb-4"
              disabled
              v-model:value="date"
              format="DD/MM/YYYY"
              style="border-radius: 7px"
            />
            <label for="selectMainProduct" class="form-label">อ้างอิง</label>
            <input
              :disabled="form.status === '0' ? false : true"
              v-model="form.refer"
              class="form-control mb-4"
            />
            <label for="selectMainProduct" class="form-label">ประเภทภาษี</label>
            <select
              :disabled="form.status === '0' ? false : true"
              v-model="form.tax_type"
              @change="taxTypeChange(form.tax_type)"
              class="form-select"
              aria-label="Select example"
            >
              <option value="1">รวมภาษี</option>
              <option value="0">ไม่มีภาษี</option>
              <option value="2">แยกภาษี</option>
            </select>
          </div>

          <div class="col-sm-6">
            <h4 class="mb-6">
              <i class="bi bi-person-fill" style="font-size: 20px"></i>
              <b>ข้อมูลผู้ซื้อ</b>
            </h4>
            <label for="selectMainProduct" class="form-label required"
              >ผู้ซื้อ / ผู้จ่ายเงิน</label
            >
            <div class="row">
              <div class="col-10 col-md-10">
                <input
                  v-model="form.customer_name"
                  :disabled="form.status === '0' ? false : true"
                  class="form-control mb-4"
                  required
                />
              </div>
              <div class="col-2 col-md-2">
                <button
                  :disabled="form.status === '0' ? false : true"
                  @mouseenter="hoverSelectSalesman"
                  @click="selectSalesman"
                  class="btn btn-sm"
                  type="button"
                >
                  <i
                    class="bi bi-plus-circle-fill"
                    style="font-size: 30px; color: DimGrey"
                  ></i>
                </button>
              </div>
              <div v-if="hoverS">
                <SelectSalesman
                  :dialogSalesman="dialogSalesman"
                  @selectedItem="selectedItem"
                  @closeDialogSalesman="closeDialogSalesman"
                />
              </div>
            </div>

            <label for="selectMainProduct" class="form-label">รหัสลูกค้า</label>
            <input
              :disabled="form.status === '0' ? false : true"
              v-model="form.customer_code"
              class="form-control mb-4"
            />

            <label for="selectMainProduct" class="form-label">โทรศัพท์</label>
            <input
              :disabled="form.status === '0' ? false : true"
              v-model="form.customer_tel"
              class="form-control mb-4"
            />

            <label for="selectMainProduct" class="form-label">เมลล์</label>
            <input
              :disabled="form.status === '0' ? false : true"
              v-model="form.customer_email"
              class="form-control mb-4"
            />

            <label for="selectMainProduct" class="form-label">ที่อยู่</label>
            <textarea
              :disabled="form.status === '0' ? false : true"
              v-model="form.customer_address"
              class="form-control mb-4"
            />
          </div>
        </div>

        <div class="row text-end">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-sm btn-light-primary"
              :disabled="form.status === '0' ? false : true"
              style="font-size: 14px; font-weight: bold"
              @click="addItem"
              @mouseenter="hoverAddBtn"
            >
              เพิ่มสินค้า
            </button>
            <div v-if="hoverA">
              <AddItem
                :dialogAddItem="dialogAddItem"
                :dataEditItem="dataEditItem"
                @submitEditItem="submitEditItem"
                @submitAddItem="submitAddItem"
                @closeDialogAddItem="closeDialogAddItem"
              />
            </div>
          </div>
        </div>

        <div class="table-responsive" style="padding-top: 3px">
          <table
            class="table table-hover table-rounded table-striped border gy-7 gs-7"
          >
            <thead>
              <tr
                style="width: 100%"
                class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
              >
                <th>#</th>
                <th>Item code</th>
                <th>Item name</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Vat</th>
                <th>Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in form.products" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.product_code }}</td>
                <td>{{ item.product_name }}</td>
                <td>{{ item.item_amt }}</td>
                <td>{{ formatPrice(item.product_wholesale_price) }}</td>
                <td>
                  {{ formatPrice(item.item_discount ? item.item_discount : 0) }}
                </td>
                <td>
                  <!-- {{
                    formatPrice(
                      form.tax_type === "0"
                        ? 0
                        : ((parseFloat(
                            item.product_wholesale_price
                              ? item.product_wholesale_price
                              : 0
                          ) -
                            parseFloat(
                              item.item_discount ? item.item_discount : 0
                            )) *
                            parseFloat(item.item_amt ? item.item_amt : 0) *
                            7) /
                            100
                    )
                  }} -->
                  {{ form.tax_type === "0" ? "0%" : "7%" }}
                </td>
                <td>
                  {{
                    // form.tax_type === "0"
                    //   ? (parseFloat(
                    //       item.product_cost_price ? item.product_cost_price : 0
                    //     ) -
                    //       parseFloat(
                    //         item.item_discount ? item.item_discount : 0
                    //       )) *
                    //     parseFloat(item.item_amt ? item.item_amt : 0)
                    //   : form.tax_type === "1"
                    //   ? (parseFloat(
                    //       item.product_cost_price ? item.product_cost_price : 0
                    //     ) -
                    //       parseFloat(
                    //         item.item_discount ? item.item_discount : 0
                    //       )) *
                    //       parseFloat(item.item_amt ? item.item_amt : 0) -
                    //     ((parseFloat(
                    //       item.product_cost_price ? item.product_cost_price : 0
                    //     ) -
                    //       parseFloat(
                    //         item.item_discount ? item.item_discount : 0
                    //       )) *
                    //       parseFloat(item.item_amt ? item.item_amt : 0) *
                    //       7) /
                    //       100
                    //   : (parseFloat(
                    //       item.product_cost_price ? item.product_cost_price : 0
                    //     ) -
                    //       parseFloat(
                    //         item.item_discount ? item.item_discount : 0
                    //       )) *
                    //     parseFloat(item.item_amt ? item.item_amt : 0)
                    formatPrice(
                      (parseFloat(
                        item.product_wholesale_price
                          ? item.product_wholesale_price
                          : 0
                      ) -
                        parseFloat(
                          item.item_discount ? item.item_discount : 0
                        )) *
                        parseFloat(item.item_amt ? item.item_amt : 0)
                    )
                  }}
                </td>
                <td>
                  <div class="row">
                    <div class="col-sm-6 d-flex justify-content-md-end">
                      <button
                        type="button"
                        class="btn btn-link btn-color-muted btn-active-color-success p-0"
                        @click="goToEditItem(index)"
                        @mouseenter="hoverAddBtn"
                        :disabled="form.status === '0' ? false : true"
                      >
                        <i class="bi bi-pencil-square"></i>
                      </button>
                    </div>
                    <div class="col-sm-6">
                      <button
                        type="button"
                        class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                        @click="goToDeleteItem(index)"
                        :disabled="form.status === '0' ? false : true"
                      >
                        <i class="bi bi-trash3-fill"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row d-flex justify-content-center mt-4">
          <div class="col-sm-8 mb-4">
            <!-- <label for="selectMainProduct" class="form-label"
              >ช่องทางจัดส่ง</label
            >
            <input v-model="form.delivery_type" class="form-control mb-4" /> -->
            <label for="selectMainProduct" class="form-label">หมายเหตุ</label>
            <textarea
              :disabled="form.status === '0' ? false : true"
              v-model="form.note"
              class="form-control"
            />
          </div>

          <div class="col-sm-4">
            <label for="selectMainProduct" class="form-label">ส่วนลด</label>
            <input
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              :disabled="form.status === '0' ? false : true"
              v-model="form.discount"
              @change="taxTypeChange"
              class="form-control mb-4"
            />

            <!-- <label for="selectMainProduct" class="form-label">ค่าจัดส่ง</label>
            <input
              v-model="form.delivery_price"
              @change="taxTypeChange"
              class="form-control mb-4"
            /> -->
          </div>
        </div>

        <div class="row d-flex justify-content-center mt-2" style="color: grey">
          <div class="col-sm-7" />
          <div class="col-sm-5">
            <div class="row mb-4" style="font-size: 16px">
              <div class="col-sm-6 d-flex justify-content-start">
                มูลค่ารวมก่อนภาษี
              </div>
              <div class="col-sm-6 d-flex justify-content-end">
                {{ form.total_price_before_vat.toFixed(2) }}
              </div>
            </div>

            <div class="row mb-4" style="font-size: 16px">
              <div class="col-sm-6 d-flex justify-content-start">
                รวมภาษีมูลค่าเพิ่ม
              </div>
              <div class="col-sm-6 d-flex justify-content-end">
                {{ form.total_price_include_vat.toFixed(2) }}
              </div>
            </div>

            <div class="row mb-4" style="font-size: 16px">
              <div class="col-sm-6 d-flex justify-content-start">มูลค่ารวม</div>
              <div class="col-sm-6 d-flex justify-content-end">
                {{ form.total_price_summary.toFixed(2) }}
              </div>
            </div>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-sm-6" />
          <div class="col-sm-6">
            <div
              class="row mb-4 p-5"
              style="
                background-color: LightGrey;
                border-radius: 7px;
                font-size: 20px;
                font-weight: bold;
              "
            >
              <div class="col-sm-6 d-flex justify-content-start">
                มูลค่ารวมสุทธิ
              </div>
              <div class="col-sm-6 d-flex justify-content-end">
                {{ form.final_price.toFixed(2) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-12">
            <button
              v-if="form.status !== '0'"
              type="button"
              class="btn btn-sm btn-secondary"
              style="font-size: 14px; font-weight: bold"
              @click="goBack()"
            >
              กลับ
            </button>

            <button
              v-if="form.status === '0'"
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loadingUpdate"
            >
              <div
                v-if="loadingUpdate"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              บันทึก
            </button>

            <button
              v-if="form.status === '0'"
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import AddItem from "./components/dialogAddItem.vue";
import SelectSalesman from "./components/dialogCustomer.vue";

import Swal from "sweetalert2/dist/sweetalert2.js";
import Loader from "../../components/loaderUpdate.vue";

import saleApi from "@/api/sale/";

import numbro from "numbro";

export default {
  components: {
    AddItem,
    SelectSalesman,
    Loader,
  },
  setup() {
    document.title = "BELUCA | แก้ไขรายการขายส่งสินค้า";
  },
  data: () => ({
    searchProduct: "",

    isSubmit: false,
    loading: false,
    isSubmit: false,
    loadingUpdate: false,

    dialogAddItem: false,
    dialogSalesman: false,

    hoverS: false,
    hoverA: false,

    dataEditItem: {},
    indexEditItem: 0,

    date: "",
    form: {
      selectMainProduct: "",
      selectSecondaryProduct: "",
      ProductName: "",
      detail: "",
      barcode: "",
      pog: "",
      retailPrice: "",
      proPrice: "",
      wholesalePrice: "",
      min: "",
      max: "",

      customerId: "",
      customer_name: "",
      customer_code: "",
      customer_tel: "",
      customer_email: "",
      customer_address: "",
      refer: "",
      tax_type: "1",
      // delivery_type: "",
      // delivery_price: 0,
      discount: 0,
      note: "",
      total_price_before_vat: 0,
      total_price_include_vat: 0,
      total_price_summary: 0,
      final_price: 0,
      products: [],
      status: "0",
      sales_type: "0",
    },
    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
  }),

  created() {
    this.getOne();
  },

  methods: {
    async getOne() {
      this.loading = true;
      let getOneResponse = {};
      try {
        getOneResponse = await saleApi.salesOrder.getOne(this.$route.query.id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        await this.bindingForm(getOneResponse.data);
        this.date = dayjs(new Date(getOneResponse.data.createdAt));
      }

      this.loading = false;
    },
    async bindingForm(item) {
      this.form = {
        ...item,
        products: [],
      };
      if (item.salesOrderItems.length > 0) {
        await item.salesOrderItems.forEach((element) => {
          this.form.products.push({
            ...element.product,
            item_amt: element.item_amt,
            item_discount: element.item_discount,
          });
        });
        this.form.customer_name = item.customer.customer_name;
        // this.totalByVatType();
      }
    },
    async submit() {
      let updateResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      this.form = {
        ...this.form,
        status: this.form.status === "2" ? "0" : this.form.status,
        companyId: parseInt(localStorage.getItem("companyId")),
        companyBranchId: parseInt(localStorage.getItem("companyBranchId")),
      };
      if (this.isValid) {
        this.loadingUpdate = true;
        updateResponse = await saleApi.salesOrder.update(
          this.$route.query.id,
          this.form
        );
        if (updateResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: `แก้ไขสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.push({
              path: "/sale/SA2-1",
              query: {},
            });
          });
          this.loadingUpdate = false;
        } else {
          Swal.fire({
            icon: "error",
            title: "แก้ไขไม่สำเร็จ !",
            showConfirmButton: false,
            timer: 1500,
          });
          this.loadingUpdate = false;
        }
      }
    },
    checkFormIsEmpty() {
      !this.form.customer_name ? (this.isValid = false) : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    goBack() {
      this.$router.go(-1);
    },
    hoverAddBtn() {
      this.hoverA = true;
      this.hoverS = false;
    },

    // product
    addItem() {
      this.dialogAddItem = true;
      this.dataEditItem = {};
    },
    submitAddItem(item) {
      this.form.products.push(item);
      this.taxTypeChange();
    },
    submitEditItem(item) {
      this.form.products.splice(this.indexEditItem, 1, item);
      this.taxTypeChange();
    },
    closeDialogAddItem() {
      this.dialogAddItem = false;
    },
    goToEditItem(index) {
      this.dataEditItem = this.form.products[index];
      this.indexEditItem = index;
      this.dialogAddItem = true;
    },
    goToDeleteItem(index) {
      Swal.fire({
        icon: "question",
        text: `ต้องการลบรายการที่ ${index + 1} สินค้าใช่หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (index > 0) {
            this.form.products.splice(index, 1);
            this.taxTypeChange();
          } else {
            this.form.products.shift();
            this.taxTypeChange();
          }
        }
      });
    },

    // salesman
    hoverSelectSalesman() {
      this.hoverS = true;
      this.hoverA = false;
    },
    selectSalesman() {
      this.dialogSalesman = true;
    },
    closeDialogSalesman() {
      this.dialogSalesman = false;
    },
    selectedItem(item) {
      this.form.customerId = item.id;
      this.form.customer_name = item.customer_name;
      this.form.customer_code = item.customer_code;
      this.form.customer_tel = item.customer_tel;
      this.form.customer_email = item.customer_email;
      this.form.customer_address = item.customer_address;
    },

    //total
    taxTypeChange(val) {
      if (this.form.products.length) {
        if (this.form.tax_type === "0") {
          this.taxNotIncluded();
        }
        if (this.form.tax_type === "1") {
          this.taxIncluded();
        }
        if (this.form.tax_type === "2") {
          this.taxSeparate();
        }
      } else {
        this.form.total_price_before_vat = 0;
        this.form.total_price_include_vat = 0;
        this.form.total_price_summary = 0;
        this.form.final_price = 0;
      }
    },

    taxNotIncluded() {
      let total = 0;
      let vat = 0;

      this.form.products.forEach((element) => {
        if (element.product_wholesale_price && element.item_discount) {
          total +=
            (parseFloat(element.product_wholesale_price) -
              parseFloat(element.item_discount)) *
            parseFloat(element.item_amt);
          vat +=
            ((parseFloat(element.product_wholesale_price) -
              parseFloat(element.item_discount)) *
              parseFloat(element.item_amt) *
              7) /
            100;
        } else if (element.product_wholesale_price && !element.item_discount) {
          total +=
            parseFloat(element.product_wholesale_price) *
            parseFloat(element.item_amt);
          vat +=
            (parseFloat(element.product_wholesale_price) *
              parseFloat(element.item_amt) *
              7) /
            100;
        }
      });

      if (this.form.discount) {
        this.form.total_price_before_vat =
          parseFloat(total) - parseFloat(this.form.discount);

        this.form.total_price_include_vat = 0;

        this.form.total_price_summary =
          parseFloat(total) - parseFloat(this.form.discount);

        this.form.final_price =
          parseFloat(total) - parseFloat(this.form.discount);
      } else {
        this.form.total_price_before_vat = parseFloat(total);

        this.form.total_price_include_vat = 0;

        this.form.total_price_summary = parseFloat(total);

        this.form.final_price = parseFloat(total);
      }
    },

    taxIncluded() {
      let total = 0;
      let vat = 0;

      this.form.products.forEach((element) => {
        if (element.product_wholesale_price && element.item_discount) {
          total +=
            (parseFloat(element.product_wholesale_price) -
              parseFloat(element.item_discount)) *
            parseFloat(element.item_amt);
          vat +=
            ((parseFloat(element.product_wholesale_price) -
              parseFloat(element.item_discount)) *
              parseFloat(element.item_amt) *
              7) /
            100;
        } else if (element.product_wholesale_price && !element.item_discount) {
          total +=
            parseFloat(element.product_wholesale_price) *
            parseFloat(element.item_amt);
          vat +=
            (parseFloat(element.product_wholesale_price) *
              parseFloat(element.item_amt) *
              7) /
            100;
        }
      });

      if (this.form.discount) {
        this.form.final_price =
          parseFloat(total) - parseFloat(this.form.discount);
        vat = (parseFloat(this.form.final_price) * 7) / 100;

        this.form.total_price_before_vat =
          parseFloat(total) - parseFloat(this.form.discount) - parseFloat(vat);

        this.form.total_price_include_vat = vat;

        this.form.total_price_summary =
          parseFloat(total) - parseFloat(this.form.discount);
      } else {
        this.form.total_price_before_vat = parseFloat(total) - parseFloat(vat);

        this.form.total_price_include_vat = vat;

        this.form.total_price_summary = parseFloat(total);

        this.form.final_price = parseFloat(total);
      }
    },

    taxSeparate() {
      let total = 0;
      let vat = 0;

      this.form.products.forEach((element) => {
        if (element.product_wholesale_price && element.item_discount) {
          total +=
            (parseFloat(element.product_wholesale_price) -
              parseFloat(element.item_discount)) *
            parseFloat(element.item_amt);
          vat +=
            ((parseFloat(element.product_wholesale_price) -
              parseFloat(element.item_discount)) *
              parseFloat(element.item_amt) *
              7) /
            100;
        } else if (element.product_wholesale_price && !element.item_discount) {
          total +=
            parseFloat(element.product_wholesale_price) *
            parseFloat(element.item_amt);
          vat +=
            (parseFloat(element.product_wholesale_price) *
              parseFloat(element.item_amt) *
              7) /
            100;
        }
      });

      if (this.form.discount) {
        vat = ((parseFloat(total) - parseFloat(this.form.discount)) * 7) / 100;

        this.form.final_price =
          parseFloat(total) + parseFloat(vat) - parseFloat(this.form.discount);

        this.form.total_price_before_vat =
          parseFloat(total) - parseFloat(this.form.discount);

        this.form.total_price_include_vat = vat;

        this.form.total_price_summary =
          parseFloat(total) + parseFloat(vat) - parseFloat(this.form.discount);
      } else {
        vat = (parseFloat(total) * 7) / 100;

        this.form.final_price = parseFloat(total) + parseFloat(vat);

        this.form.total_price_before_vat = parseFloat(total);

        this.form.total_price_include_vat = vat;

        this.form.total_price_summary = parseFloat(total) + parseFloat(vat);
      }
    },

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },
  },
};
</script>

<style scoped>
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

#text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
</style>
